<template>
    <div class="data-detail" >
        <div class="det-title flex-start">
            <el-button @click="back" size="mini">返回</el-button>
            <div class="title">数据拉取</div>
        </div>
        <div class="det-content">
            <div class="tip">
                <p>启用企微审批数据源时会<span>默认拉取最近30天的审批数据</span>，工作台展示的审批统计数据为拉取的起始时间到当前时间段内的统计数据，<br/>如有需要，可手动拉取之前的审批数据进行统计。</p>
            </div>
            <div class="pullDate_main">
                <el-button @click="pulltype=true" type="primary" size="small">拉取数据</el-button>
                <div class="rigth">
                    <div>当前审批数据统计起始时间：
                        <span class="currentDate">{{ is_lq?sp_last_starttime:'暂未拉取' }}</span>
                    </div>
                </div>
            </div>

            <div class="data_list">
                <el-table :data="qwsp_list" style="width: 100%" v-loading="loading">
                    <el-table-column prop="task_name" label="任务名称" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div class="task-name">{{ scope.row.task_name }}</div>
                            <el-progress 
                                :percentage="percentStatus(scope.row)" 
                                :stroke-width="3" 
                                :show-text=false
                                :color="customColorMethod(scope.row)">
                            </el-progress>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" width="150">
                        <template slot-scope="scope">
                            <div class="fail-status" v-if="scope.row.status==1">排队中</div>
                            <div class="ing-status" v-else-if="scope.row.status==2">进行中</div>
                            <div class="pass-status" v-else-if="scope.row.status==10">已过期</div>
                            <div class="success-status" v-else>已完成</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" width="230">
                        <template slot-scope="scope">
                            <div>{{ scope.row.create_time }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="finish_time" label="完成时间" width="230">
                        <template slot-scope="scope">
                            <div>{{ scope.row.finish_time }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建人" width="120">
                        <template slot-scope="scope">
                            <div>
                            <wxComponent v-if="allIsWx3=='true'" type="userName" :openid="scope.row.create_userid"/>
                            <span v-else>{{scope.row.create_userid}}</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="flex-end page-cont" v-show="qwsp_list.length">
                    <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        layout="prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>

        <!-- 拉起数据 -->
        <el-dialog
            title="拉取数据"
            :visible.sync="pulltype"
            width="450px">
            <div class="pullDate">
                选择拉取起始时间：
                <el-date-picker
                    style="width:200px;"
                    class="selectDate"
                    v-model="pulldate"
                    type="date"
                    :editable="false"
                    :clearable="false"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions"
                    placeholder="选择日期">
                </el-date-picker>
            </div>
            <p class="pullDate_p"><span class="el-icon-info"></span> 只能选择当前拉取起始时间之前一个月之内的数据</p>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" style="margin:0 15px 0 0;" @click="pulltype=!pulltype">取 消</el-button>
                <el-button size="small" type="primary" @click="getPullQwsp">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {pullDate,pullQwsp,getTaskList,getTaskForZJ} from '@/api/workbench'
import wxComponent from "@/components/wx";
export default {
    data() {
        return {
            loading: false, // 加载状态
            allIsWx3: '', //

            // 获取已经拉取审批的时间段
            is_lq: true, // 是否有拉取
            sp_last_starttime: '', // 起始时间
            sp_last_endtime: '', // 结束时间

            pulldate:'', // 拉取时间
            pulltype:false, // 拉取时间状态
            pickerOptions:{ // 区间禁用
                disabledDate: this.disabledDate
            },

            // 拉取列表
            page: 1, // 页码
            total: 0, // 数量
            limit: 7, // 每页数量
            qwsp_list:[], // 列表
            timer:0
        }
    },
    components: {
        wxComponent
    },
    computed: {
        percentStatus() {
            return function (row) {
                const {job_num, success_job_num} = row
                const per=((success_job_num / job_num) * 100)>100 ? 100 : (success_job_num / job_num) * 100
                return job_num === 0 ? 0 : per
            }
        },
        customColorMethod() {
            return function (row) {
                const {job_num, success_job_num} = row
                let percentage =job_num === 0 || undefined || '' ? 0 : (success_job_num / job_num) * 100
                percentage > 100 ? percentage=100 : ''
                if (percentage < 30) {
                    return '#e6a23c';
                } else if (percentage < 99) {
                    return '#0082ef';
                } else {
                    return '#67c23a';
                }
            }
        },
    },
    created() {
        this.allIsWx3 = sessionStorage.getItem('is_wx3')
        this.getPullDate() // 获取已经拉取审批的时间段
        this.onGetTaskList() // 获取拉取数据
    },
    methods: {
        //返回
        back(){
          
            this.$router.push('/home/datamana/index')
        },

        // 获取已经拉取审批的时间段
        getPullDate(){
            pullDate().then(res=>{
                if(res.code===200){
                    let date = ''
                    if(res.data.sp_last_starttime==0){
                        this.is_lq = false
                        date = this.getCurrentDate()
                    }else{
                        date = res.data.sp_last_starttime
                    }
                    this.pulldate = date
                    this.sp_last_starttime = date
                    this.sp_last_endtime=res.data.sp_last_endtime
                }else{
                    this.$message({message: res.message,type: 'warning'})
                }
            }).catch(err=>{
                console.log(err,'错误信息')
            })
        },

        //禁止选择起始时间以后的时间
        disabledDate(time){
            
            let date30 = Date.parse(this.sp_last_starttime) - 1000 * 60 * 60 * 24 * 30; //最后一个数字30可改，30天的意思
            return time.getTime() < date30 || time.getTime() > Date.parse(this.sp_last_starttime);
        },

        // 拉取企微审批
        getPullQwsp(){
            pullQwsp({
                type: 1, // 拉取方式：1 选择起始时间之前的时间 2选择结束时间之后的时间
                time: this.pulldate // 	拉取选择的时间
            }).then(res=>{
                if(res.code===200){
                    this.$message({message: res.message,type: 'success'});
                    this.onGetTaskList() // 获取列表
                    this.pulltype = !this.pulltype
                }else{
                    this.$message({message: res.message,type: 'warning'})
                }
            }).catch(err=>{
                console.log(err,'错误信息')
            })
        },

        // 获取列表
        onGetTaskList(){
            this.loading = true
            if(this.allIsWx3=='false'){
                getTaskForZJ({
                    type: 3, // 搜索条件，搜索某个类型的异步任务 异步任务类型：1 导出模板 2 模板启用队列任务 3企微审批拉取任务
                    page: this.page, // 页码
                    limit: this.limit, // 每页多少条数据
                }).then(res=>{
                    if(res.code===200){
                        this.qwsp_list = res.data.list
                        this.total=res.data.total
                        // 如有排队或者进行中每隔10秒请求一次
                        this.qwsp_list.map((arr)=>{
                            if(arr.status==1||arr.status==2){
                            let timer = setTimeout(()=>{
                                if(this.$route.path == '/home/datamana/datapull'){
                                     this.onGetTaskList()
                                 }else{
                                     clearTimeout(timer)
                                 }
                                },5000)
                            }
                        })
                     
                    }else{
                        this.$message({message: res.message,type: 'warning'})
                    }
                    this.loading=false
                }).catch(err=>{
                    console.log(err,'错误信息')
                })
            }else{
                getTaskList({
                    userid: '', // 搜索条件，搜索某个人的异步任务
                    type: 3, // 搜索条件，搜索某个类型的异步任务 异步任务类型：1 导出模板 2 模板启用队列任务 3企微审批拉取任务
                    page: this.page, // 页码
                    limit: this.limit, // 每页多少条数据
                }).then(res=>{
                    if(res.code===200){
                        this.qwsp_list = res.data.list
                        this.total=res.data.total
                        this.qwsp_list.map((arr)=>{
                            if(arr.status==1||arr.status==2){
                            let timer = setTimeout(()=>{
                                if(this.$route.path == '/home/datamana/datapull'){
                                     this.onGetTaskList()
                                 }else{
                                     clearTimeout(timer)
                                 }
                                },5000)
                            }
                        })
                    }else{
                        this.$message({message: res.message,type: 'warning'})
                    }
                    this.loading=false
                }).catch(err=>{
                    console.log(err,'错误信息')
                })
            }
        },
        // 分页
        handleCurrentChange(page){
            this.page = page
            this.onGetTaskList()
        },

        // 获取当前日期时间
        getCurrentDate() {
            let time = new Date
            let year = time.getFullYear();
            let month = time.getMonth() + 1;
            let day = time.getDate();
            return year + '-' + this.replenish(month) + '-' + day +' '+ this.replenish(time.getHours()) +':'+ this.replenish(time.getMinutes()) +':'+ this.replenish(time.getSeconds())
        },
        // 小于10自动补0
        replenish(data){
            return (data < 10)?'0'+data:data
        },
    }
}
</script>

<style lang="less" scoped>
    .data-detail{
        .det-title{padding: 0 24px;height: 48px;background: #F8F9FA;border-bottom: 1px solid #DBDBDB;
            .title{flex:1;text-align: center;}
        }
        .det-content{padding: 20px;
            .tip {padding: 0 0 30px;border-bottom: 1px  solid #ccc;font-size: 18px;font-weight: 500;line-height:32px;font-family: PingFangSC-Medium;margin:37px 0 0;
                span{color: #004580;}
            }
            .pullDate_main{display: flex;justify-content: space-between;align-items: center;margin: 30px 0 0 0;color: #004580;font-size:14px;font-weight:400;
            }

            // table
            .data_list{margin: 20px 0 0 0;
                /deep/.has-gutter{
                    tr, th, td { background: #DFE9F5 !important; }
                }
                .success-status{color: #07C160;}
            }

            // 分页
            .flex-end{margin: 20px 0 0 0;}
        }

        // 弹框样式
        .pullDate{display: flex;align-items: center;margin: 0 0 20px;}
        .pullDate_p{color: #F56C6C;}
    }
</style>

<style>
    .el-popconfirm__main{ margin: 0 0 10px;max-width: 180px;}
</style>
